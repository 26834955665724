<template>
  <blog-component />
</template>

<script>
import blogComponent from "../../components/User/Blog/blogComponent.vue";
export default {
  name: "BlogView",
  components: { blogComponent },
  mounted(){
    this.$vuetify.goTo(0,0)
  },
};
</script>

<style>
</style>