<template>
  <v-col class="px-0 py-0" style="text-align: center">
    <v-main>
      <v-container>
        <page-step :pages="[{ id: 1, name: 'Блог', link: '/blog' }]" />
        <h2 class="pageName" style="margin-top: 20px">
          {{ "blog_title" | localize }}
        </h2>
        <Loader v-if="showLoader" />
        <v-col class="px-0 py-0" v-else>
          <v-row no-gutters justify="center" style="margin-top: 40px">
            <category-list
              :categoryList="categoriesList"
              @setCategory="setCategory"
            />
          </v-row>
          <Loader v-if="showBlogLoader" />
          <v-row no-gutters style="margin-top: 40px" v-else>
            <v-col
              cols="12"
              xl="4"
              lg="4"
              md="4"
              sm="12"
              v-for="blog in blogList"
              :key="blog.id"
              class="blogCard"
            >
              <blog-card :blog="blog" :isPopular="blog.is_popular == 'Yes'" />
            </v-col>
          </v-row>
        </v-col>
      </v-container>
    </v-main>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import contentService from "../../../requests/Content/contentService";
import CategoryList from "../../UI/Lists/categoryList.vue";
import Loader from "../../UI/Loader.vue";
import PageStep from "../../UI/pageStep.vue";
import blogCard from "./blogCard.vue";
export default {
  components: { blogCard, PageStep, CategoryList, Loader },
  data: () => ({
    categoriesList: [],
    blogList: [],
    showLoader: true,
    showBlogLoader: false,
    activeCategory: 0,
    page: 1,
  }),
  mounted() {
    this.getCategory();
    this.getBlogs();
  },
  methods: {
    setCategory(category) {
      this.showBlogLoader = true;
      this.activeCategory = category;
      this.getBlogs();
    },
    async getCategory() {
      await contentService.getVisibleCategory(this.page).then((res) => {
        if (res.status == "Success") {
          this.categoriesList = res.data;
          this.categoriesList.unshift({
            id: 0,
            translations: {
              slug: 0,
              name: "Всі новини",
            },
          });
        }
      });
    },
    async getBlogs() {
      await contentService
        .getBlog(this.activeCategory, this.page)
        .then((res) => {
          if (res.status == "Success") {
            this.blogList = res.data;
            this.showLoader = false;
            this.showBlogLoader = false;
          }
        });
    },
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  watch: {
    locale: {
      handler() {
        this.showLoader = true;
        this.getCategory();
        this.getBlogs();
      },
    },
  },
};
</script>

<style scoped>
.blogCard {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.blogCard:nth-child(3n) {
  padding-left: 0px !important;
}
.blogCard:nth-child(3n) {
  padding-right: 0px !important;
}
</style>